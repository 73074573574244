import update from 'immutability-helper'
import Face from '../assets/GowthamSelvaraj.jpg'
import Home from '../assets/home.svg';
import HomeMobile from '../assets/homeMobile.svg';
import Header from '../assets/header-background.jpg';
import Footer from '../assets/footer.jfif';
let defaultState = {
  name: 'Danya',
  text: 'type something',
  face: Face,
  home: Home,
  homeMobile:HomeMobile,
  header: Header,
  hgt: window.innerHeight,
  footer: Footer,
  wdt: window.innerWidth,
  content: '',
  title: '',
  editor: null,
  thumbnail: '',
  rawHTML: '',
  article: {},
  listArticle: [],
  userName:'admin',
  passkey: '@$(M]55ZUtq]9x.',
  authenticated: false,
}

const main = (state = defaultState, action) => {
  switch (action.type) {
    case 'CHANGE_STATE_PROPS':
      console.log('data in reducer', action.state)
      return update(state, {
        [action.state.prop]: {$set: action.state.value}
      })
    default:
      return state
  }
}

export default main
