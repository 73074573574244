import React from 'react'

import RouterHandler from './RouteHandler';

import './App.css';

import {changeStateProps } from './actions';
import { connect } from 'react-redux'


function App(props){
 
  return(
       <RouterHandler/>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
      face: state.main.face,
      ...ownProps
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeStateProps: (prop, value) => {
      dispatch(changeStateProps(prop, value))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App)