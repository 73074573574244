import React  from 'react'
import { connect } from 'react-redux'

import { changeStateProps } from '../../../actions'



//material ui imports
import {AppBar,Toolbar,Typography,Avatar,makeStyles,Tabs,Tab } from '@material-ui/core'

import Sidebar from './Sidebar';

const useStyles = makeStyles((theme) =>({
    root:{
        backgroundColor: 'white',
        color: 'black',
    },
    large:{
        width: theme.spacing(8),
        height: theme.spacing(8),
        userSelect: 'none'
    },
    name:{
        [theme.breakpoints.up('xs')]:{
            fontSize: 24,
            flex: 1,
        },
        [theme.breakpoints.up('sm')]:{
            fontSize: 32,
            flex: 0.5
        },
        color: 'black',
        fontFamily: 'Blastimo',
        padding: theme.spacing(1),
        userSelect: 'none'
        
    },
    category:{
        [theme.breakpoints.up('xs')] :{
            display: 'none',
        },
        [theme.breakpoints.up('sm')] :{
            display: 'flex',
            marginLeft: theme.spacing(5)
        },
    },
    btn:{
        width: theme.spacing(20),
        height:theme.spacing(5),
        color: 'white',
        fontSize: 24
    },
    
}))

export function Bar(props){
        const classes = useStyles();
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
        return (
            <AppBar elevation={0}  className={classes.root}>
                <Toolbar variant="dense">
                    <Avatar alt="Gowtham Selvaraj" src={require('../../../assets/GowthamSelvaraj.jpg')} className={classes.large} />
                    <Typography className={classes.name}>
                        Gowtham Selvaraj
                    </Typography>                    
                    <Typography variant="h6" className={classes.category} noWrap>
                        <Tabs variant="scrollable" value={value}    onChange={handleChange}>
                            <Tab label="ABOUT"  href="#about"  />
                            <Tab label="SKILLS" href="#skills" />
                            <Tab label="PROJECTS" href="#projects" />
                            <Tab label="CONTACT" href="#contact" />
                        </Tabs>
                    </Typography>
                    <Sidebar/>
                </Toolbar>
            </AppBar>
        )
}

const mapStateToProps = (state, ownProps) => {
    return {
        face: state.main.face,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Bar)
