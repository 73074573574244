import React from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';
import { connect } from 'react-redux';

import { changeStateProps } from './actions';

//components
import Home from './components/home';
import Profile from './components/profile';
import Contact from './components/contact';
import Projects from './components/projects';
import About from './components/about';
// import CreateArticle from './components/blogs/createArticle';
import BlogsGambit from './components/blogs/materialTemplate/Blog';
import MultiRoutes from './MultiRoutes';


function Portfolio(props){
    return(
        <div style={{fontFamily: 'Georgia, serif'}}>
            <Home />
            <About/>
            <Projects/>
            <Profile/>
            <Contact/>
        </div>
    );
}


function RouterHandler(props){
    return(
        <Router>
            <Route exact path="/" component={BlogsGambit}/>
            <Route exact path="/profile" component={Portfolio}/>
            <Route path="/posts" render={()=>(
                <MultiRoutes {...props}/>
            )} />
        </Router>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        listArticle: state.main.listArticle,
        article: state.main.article,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(RouterHandler)