  
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { CircularProgress, createMuiTheme,ThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux'; 


import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
//import Main from './Main';
//import Sidebar from './Sidebar';
import Footer from './Footer';
import axios from 'axios';
import config from '../../../config';

import { changeStateProps } from '../../../actions';



const theme = createMuiTheme({  
  typography: {
    fontFamily: [
      "serif"
    ].join(","),
    fontSize: 18
  }
});





function Blog(props) {
  var rand = Math.floor(Math.random()*10000000);
  const [loaded,setLoaded] = React.useState(false);
  React.useEffect(()=>{
    
    window.addEventListener('load', async()=>{
     await axios.get(config.API_URL+'article/view').then(async(res)=>{
       if(res.data.articles==null){
         return "Empty";
       }
          props.changeStateProps('listArticle',JSON.parse(JSON.stringify(res.data.articles)));
          document.title="Blogs Gambit"
          setLoaded(true);
     })      
      .catch(err =>{
        console.log(err);
      });
    })
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container style={{fontFamily: 'sans-serif'}} maxWidth="lg">
        <Header title="BlogsGambit"  />
        {
          loaded?(
            <main>
          <MainFeaturedPost post={props.listArticle[props.listArticle.length-1]} />
          <Grid container spacing={4}>
            {props.listArticle.map((post,idx) => (
                <FeaturedPost key={rand+idx} post={post} />
            ))}
          </Grid>
         {/* <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="From the firehose" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        */}
        </main>
          ):(
            <CircularProgress size={40} style={{marginLeft: '50%',marginTop: '25%'}}/>
          )
        }
        
      </Container>
      <Footer
       title="BlogsGambit"  />
    </ThemeProvider>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
      listArticle: state.main.listArticle,
      ...ownProps
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeStateProps: (prop, value) => {
      dispatch(changeStateProps(prop, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)