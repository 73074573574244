import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {changeStateProps } from '../../actions';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    root:{
        [theme.breakpoints.down('sm')]:{
            paddingTop: '10%'
        },
        border: '20px solid white',
        textAlign: 'center',
        borderBottom: '0px',
        borderTop: '0px',
    }
}))


export const About= (props) => {
    const classes = useStyles();
    return (
            <div id="skills"  className={classes.root} style={{height: props.hgt,width: '99%'}}>
                <div  style={{paddingTop: '5%'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                             <Typography style={{fontFamily: 'librebaskerville-regular',fontSize: '54px',fontWeight: 'bold'}}>
                                Skills
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                             <Typography style={{fontFamily: 'librebaskerville-regular',fontSize: '30px',textAlign: 'left',paddingLeft: '48%'}}>
                             C++<br/>
                            JAVA<br/>
                            HTML<br/>
                            CSS<br/>
                            JavaScript<br/>
                            ReactJs<br/>
                            NodeJS<br/>
                            MySQL<br/>
                            MongoDB<br/>
                            React Native & Redux<br/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

            </div>
    )
}

About.propTypes = {
    props: PropTypes
}

const mapStateToProps = (state, ownProps) => {
    return {
        home: state.main.homeMobile,
        header: state.main.header,
        wdt: state.main.wdt,
        hgt: state.main.hgt,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(About);