import React, { useState } from 'react'
import { connect } from 'react-redux'
import {changeStateProps } from '../../actions';


import {Frame } from 'framer';
import { Paper,makeStyles, Typography, Button, Grid, TextField } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';

const useStyles=makeStyles((theme)=>({
  home:{
    [theme.breakpoints.up('sm')]:{
        backgroundColor: 'transparent'
    },
},
}))

function FramerWrapper(props){
  const classes = useStyles();

  return(
      <div style={{width: props.wdt, height: props.hgt}} className={classes.home}>
          {props.children}
      </div>
  )
}

function Contact(props){
const [name, setName] = useState('');
   const [subject, setSubject] = useState('');
   const [email, setEmail] = useState('');
   const [message, setMessage] = useState('');


    const handleClick = (e) => {
       e.preventDefault();
      window.open(`mailto:${email}?subject=${subject}&body=${name}: ${message}&to=${'gowtham_selvaraj@outlook.com'}`);
    }


  return(
    <div id="contact" style={{width: '90%',height: props.hgt/2,backgroundColor: 'white'}}>
      <FramerWrapper wdt={"90%"} hgt={props.hgt/2}>
          <Frame  border="10px solid white" style={{borderBottom: '0px',borderTop: '0px',backgroundColor: 'white'}}  width={'100%'} height={'50%'}  >
              <Paper elevation={10} style={{marginLeft: '5%',width: '90%'}} >
                <Grid style={{textAlign: 'center'}} alignContent="space-around" container spacing={3}>
                    <Grid item xs={4}>
                        <EmailIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontFamily: 'open-sans serif'}}>
                            Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                            <TextField style={{fontFamily: 'open-sans serif'}}  value={name}  onChange={(e)=>setName(e.target.value)} size="small"  />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontFamily: 'open-sans serif'}}>
                            Email:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField style={{fontFamily: 'open-sans serif'}}  onChange={(e)=>setEmail(e.target.value)} size="small"  />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontFamily: 'open-sans serif'}}>
                            Subject:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField style={{fontFamily: 'open-sans serif'}} value={subject} onChange={(e)=>setSubject(e.target.value)} size="small"  />

                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontFamily: 'open-sans serif'}}>
                            Message:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                            <TextField value={message}  onChange={(e)=>setMessage(e.target.value)} size="small"  />
                    </Grid>
                    
                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={handleClick} style={{fontFamily: 'open-sans serif',backgroundColor: 'black',color: 'white'}}>
                            SEND
                        </Button>
                    </Grid>
                    
                </Grid>
              </Paper>
          </Frame>
      </FramerWrapper>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
    return {
        home: state.main.home,
        header: state.main.header,
        hgt: state.main.hgt,
        footer: state.main.footer,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Contact);