import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;
  if(post===undefined||post===null){
    return <h1>Page Not Found</h1>;
  }
  return (
    <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.header_image})` }}>
    {<img style={{ display: 'none' }} src={post.header_image} alt={post.description} />}
    <div className={classes.overlay} />
    <Grid container>
      <Grid item md={6}>
        <div className={classes.mainFeaturedPostContent}>
          <Typography component="h1" variant="h3" color="inherit" gutterBottom>
            {post.description}
          </Typography>
          <Typography variant="h5" color="inherit" paragraph>
            {
             post.content[0].type==='text'?(
              <div>
              { post.content[0].value.substring(0,120)}
              </div>
             ):(<div> </div>)
            }
          </Typography>
          <Link color="secondary" variant="subtitle1" href={"posts/"+post.slug}>
            {"Continue Reading"}
          </Link>
        </div>
      </Grid>
    </Grid>
  </Paper>

    );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};

