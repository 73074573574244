import React from 'react'
import { connect } from 'react-redux'
import {changeStateProps } from '../../actions';


import {Frame,Page } from 'framer';
import { Typography, makeStyles, Button } from '@material-ui/core';
import EcoIcon from '@material-ui/icons/Eco';

const useStyles=makeStyles((theme)=>({
  home:{
    [theme.breakpoints.up('sm')]:{
        backgroundColor: 'transparent',
        paddingTop : '10px'
    },
  background:{
    [theme.breakpoints.up('sm')]:{

      backgroundColor: 'black'
    },
    [theme.breakpoints.down('sm')]:{
    
    },
    border: '10px solid white',
    borderTop: '0px',
    borderBottom: '0px'

  },
},
}))

function FramerWrapper(props){
  const classes = useStyles();

  return(
      <div style={{width: props.wdt, height: props.hgt}} className={classes.home}>
          {props.children}
      </div>
  )
}

function Projects(props){
  return(
    <div id="projects" style={{width: props.wdt,height: props.hgt+125,backgroundColor: 'white'}}>
      <FramerWrapper wdt={props.wdt} hgt={props.hgt} >
         <Frame border="10px solid white" width="100%" height="100%" style={{borderTop: '0px',borderBottom: '0px',backgroundColor: 'white'}}   image={props.home}>
            <Frame  backgroundColor="transparent" x={props.wdt} y={50} width={"100%"}>
            <Typography  style={{fontSize: 48,color: 'white',textAlign: 'center',fontFamily: 'librebaskerville-regular'}}>
                PROJECTS
            </Typography>
            </Frame>
            <Page  height={"80%"} y={props.hgt/5} width={"100%"} gap={1} defaultEffect={"cube"} backgroundColor="transparent" opacity={0.7}>
              
                <Frame  color="white" backgroundColor="#073862">
                  
                  <Typography style={{color: 'white',fontSize: 24,textAlign: 'center',fontFamily: 'librebaskerville-regular'}}>
                  INVOICE BILLING SYSTEM AND REPORT GENERATOR
                  </Typography>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        A desktop application lets customer to easily enter master details, create invoices, custom reports and template
                      </Typography>
                    </Button>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        Designed using Microservice architecture to deploy core modules independently 
                      </Typography>
                    </Button>
                    <br/>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,textAlign: 'center',fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        ElectronJS, MERN, MySQL
                      </Typography>
                    </Button>
                  
                </Frame>
                <Frame color="white" backgroundColor="#073862">
                <Typography style={{color: 'white',fontSize: 24,textAlign: 'center',fontFamily: 'librebaskerville-regular'}}>
                  PRODUCT COMPARISON ENGINE
                  </Typography>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                      Web Scraping engine with MVC architecture to extract data from 8 ecommerce sites
                      </Typography>
                    </Button>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        Allows customers to compare products from various sites
                      </Typography>
                    </Button>
                    <br/>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,textAlign: 'center',fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                      MERN, PuppeteerJS, CheerioJS, and Redux 
                      </Typography>
                    </Button>
                  
                </Frame>
                <Frame color="white" backgroundColor="#073862">

                <Typography style={{color: 'white',fontSize: 24,textAlign: 'center',fontFamily: 'librebaskerville-regular'}}>
                  JYOTI SOS (FINALIST IN SMART INDIA HACKATHON)
                  </Typography>
                    <Button disabled style={{color: 'white',paddingLeft: '20%'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        Child and women security application allows users to trigger live location tracking
                      </Typography>
                    </Button>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                        Supported for Android, iOS, and web platforms
                      </Typography>
                    </Button>
                    <br/>
                    <Button disabled style={{paddingLeft: '20%',color: 'white'}}>
                      <EcoIcon fontSize="small"/>
                      <Typography  style={{color: 'white',fontSize: 12,textAlign: 'center',fontFamily: 'librebaskerville-regular',textTransform: 'none'}}>
                      React Native, MERN, and Redux 
                      </Typography>
                    </Button>

                </Frame>
                
            </Page>
         </Frame>
      </FramerWrapper>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
    return {
        home: state.main.home,
        header: state.main.header,
        hgt: state.main.hgt,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Projects);