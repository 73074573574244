import React from 'react'
import { connect } from 'react-redux'
import Appbar from './Appbar';
import { changeStateProps } from '../../actions';

//material ui imports
import {Button, Grid,IconButton,makeStyles, Typography} from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


import { Frame, Page } from 'framer';

const useStyles = makeStyles((theme)=>({
    home:{
        [theme.breakpoints.up('sm')]:{
            backgroundColor: 'transparent'
        },
    },
    name:{
        [theme.breakpoints.up('sm')]:{
            fontSize: '72px',
        },
        fontSize: '52px',
        fontFamily: 'opensans-bold, sans-serif',
        fontWeight: 'bolder'
    },
    location:{
        [theme.breakpoints.down('sm')]:{
            fontSize: '24px',
            
        },
        fontSize: '32px',fontFamily: 'librebaskerville-regular'
    },
    role:{
        [theme.breakpoints.down('sm')]:{
            fontSize: '18px'
        },
        fontSize: '28px',fontFamily: 'librebaskerville-regular',fontWeight: 'bold'
    },
    page1:{
        color: 'white',
        textAlign: 'center',
    },
    page2:{
        color: 'white',
        textAlign: 'center',
        marginTop: '15%'
    },
    page3:{
        color: 'white',
        textAlign: 'center',
        marginTop: '17%'
    }
}))
function FramerWrapper(props){
    const classes = useStyles();
  
    return(
        <div style={{width: props.wdt, height: props.hgt}} className={classes.home}>
            {props.children}
        </div>
    )
}


export function Home(props){
    const classes=useStyles();
    
    const [pg1, setPg1 ] = React.useState(1);
    const [pg2, setPg2 ] = React.useState(1);
    const [pg3,setPg3] = React.useState(1);
    const [bg, setBG]= React.useState('grey');
    const updateOpacity =(curr,prev)=>{
        if(curr===0){
            setPg1(1)
        }
        if(curr===1){
            setPg1(0.2);
            setPg2(1);
        }
        else{
            setPg2(0.2)
            setPg3(1);
        }
    }
    const onEnter = ()=>{
        setBG('transparent');
    }
    const onLeave = ()=>{
        setBG('grey');
    }
    return (
        <div >
            <Grid container id="about"  spacing={0}>
                <Grid item>
                    <Appbar/>
                </Grid>
                <Grid item>
                       <FramerWrapper wdt={'100%'} hgt={props.hgt} >
                           <Frame border="10px solid white" style={{borderBottom: '0px'}} background={{src: props.header}} width={'100%'} height={'100%'}  >
                               <Frame  animate={{x: 15}} transition={{duration: 2,repeat: Infinity}}  width={'10%'} x={props.wdt}  size={200} backgroundColor={"transparent"} y={"80%"} center="x">
                                   <IconButton disabled >
                                       <ArrowForwardIcon fontSize="large" style={{color: 'white'}}/>
                                   </IconButton>
                               </Frame>
                                <Page  onChangePage={(current,previous)=>{
                                   updateOpacity(current,previous);
                                }} defaultEffect={"pile"} width={'100%'} x={props.wdt}  size={400} backgroundColor={"transparent"} y={"50%"} center="x"  >
                                   <Frame backgroundColor={"transparent"}  opacity={pg1}>
                                       <div  className={classes.page1}>
                                           <Typography className={classes.name} >
                                           I'm Gowtham Selvaraj
                                           </Typography>
                                           <Typography className={classes.location}>
                                               I'm a coimbatore based UG student @Karpagam College of Engineering
                                           </Typography>
                                           <Typography className={classes.role}>
                                               SOFTWARE DEVELOPER
                                           </Typography>
                                       </div>

                                    </Frame>
                                    <Frame backgroundColor="transparent" opacity={pg2} >
                                        <div className={classes.page2}>
                                           <Typography style={{fontSize: '24px',fontFamily: 'librebaskerville-regular'}}>
                                               I love to design and develop full fledged cross platform applications
                                           </Typography>
                                        </div>
                                    </Frame>    
                                    <Frame backgroundColor="transparent" opacity={pg3}>
                                        <div className={classes.page3}>
                                            <Button onMouseEnter={onEnter} onMouseLeave={onLeave} variant="outlined" style={{backgroundColor: bg,color: 'white'}} target="_blank" href="https://hackerrank.com/gowthamselvaraj0">
                                            <Typography style={{fontSize: '24px',fontFamily: 'librebaskerville-regular'}}>
                                                Checkout my hacker rank profile
                                            </Typography>
                                            </Button>
                                        </div>
                                    </Frame>    
                                  
                                </Page>
                                <Frame   x={'25%'} color="white"  backgroundColor={"transparent"} y={"250%"} center="x"   >
                                    <IconButton target="_blank" href="https://linkedin.com/in/gowthamselvaraj021" style={{color: 'white'}}>
                                        <LinkedInIcon/>
                                    </IconButton>
                                    <IconButton target="_blank" href="https://github.com/G0WTHAM-SELVARAJ/" style={{color: 'white'}}>
                                        <GitHubIcon/>
                                    </IconButton>
                                </Frame>
                            </Frame>
                       </FramerWrapper>
                </Grid>
            </Grid>

        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        header: state.main.header,
        hgt: state.main.hgt,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Home)
