import React from 'react'
import { connect } from 'react-redux'
import {changeStateProps } from '../../actions';


import {Frame } from 'framer';
import { Paper,makeStyles, Typography } from '@material-ui/core';

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';


const useStyles=makeStyles((theme)=>({
  home:{
    [theme.breakpoints.up('sm')]:{
        backgroundColor: 'transparent'
    },
},
}))

function FramerWrapper(props){
  const classes = useStyles();

  return(
      <div style={{width: props.wdt, height: props.hgt}} className={classes.home}>
          {props.children}
      </div>
  )
}

function Profile(props){
  return(
    <div style={{paddingTop: '10%',width: '100%',height: props.hgt/2,backgroundColor: 'white'}}>
      <FramerWrapper wdt={"100%"} hgt={props.hgt/2}>
          <Frame  border="10px solid white" style={{borderBottom: '0px',borderTop: '0px',backgroundColor: 'white'}}  width={'100%'} height={'50%'}  >
              <Paper elevation={0}>
                <Typography style={{fontFamily: 'librebaskerville-regular',fontSize: '28px',textTransform: 'none',textAlign: 'center'}}>
                <EmojiObjectsIcon fontSize="large"/>
                  Ability to work in a dynamic environment where my skills can grow along with the organization
                </Typography >
                <br/>
                <Typography style={{fontFamily: 'librebaskerville-regular',textTransform: 'none',fontSize: '16px',textAlign: 'right',paddingRight: '5%'}}>
                  - GOWTHAM SELVARAJ
                </Typography>
              </Paper>
          </Frame>
      </FramerWrapper>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
    return {
        home: state.main.home,
        header: state.main.header,
        hgt: state.main.hgt,
        footer: state.main.footer,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Profile);