import React  from 'react'
import { connect } from 'react-redux'

import { changeStateProps } from '../../../actions'


//material ui imports
import MenuIcon from '@material-ui/icons/Menu';
import {Drawer,makeStyles, IconButton, Paper, Grid, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) =>({
    sidebar:{
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    }
}))

export function Sidebar(props){
        const classes = useStyles();
        const [anchor,setAnchor] = React.useState(false);
        const toggleDrawer = (open ) =>(e)=>{
            if(e.type===' keydown' &&(e.key ==='Tab'||e.key==='Shift')){
                return;
            }
            setAnchor(open);
        }
        return (
            <React.Fragment key={anchor}>
              <IconButton className={classes.sidebar} onClick={toggleDrawer(true)}>
                <MenuIcon/>
              </IconButton>
              <Drawer  anchor="right" open={anchor} onClose={toggleDrawer(false)}>
                <Paper style={{width: props.wdt/2}}>
                  <Grid style={{marginLeft: '5%'}} container spacing={6}>
                    <Grid  xs={12} item>
                      <IconButton  onClick={toggleDrawer(false)} href="#about">
                        <Typography style={{fontFamily: 'open-sans serif',fontSize: '24px',color: 'black'}}>
                          About
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid  xs={12} item>
                      <IconButton onClick={toggleDrawer(false)} href="#skills">
                        <Typography style={{fontFamily: 'open-sans serif',fontSize: '24px',color: 'black'}}>
                          Skills
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid  xs={12} item>
                      <IconButton onClick={toggleDrawer(false)} href="#projects">
                        <Typography style={{fontFamily: 'open-sans serif',fontSize: '24px',color: 'black'}}>
                          Projects
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid  xs={12} item>
                      <IconButton onClick={toggleDrawer(false)} href="#contact">
                        <Typography style={{fontFamily: 'open-sans serif',fontSize: '24px',color: 'black'}}>
                          Contact
                        </Typography>
                      </IconButton>
                    </Grid>

                  </Grid>
                </Paper>
              </Drawer>
            </React.Fragment>
        );
}

const mapStateToProps = (state, ownProps) => {
    return {
        face: state.main.face,
        wdt: state.main.wdt,
        height: state.main.hgt,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
