import React from 'react';
import {
    Route, useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { changeStateProps } from './actions';

//components
// import Main from './components/blogs/materialTemplate/Main';
import { CircularProgress } from '@material-ui/core';

// import axios from 'axios';
// import config from './config';



function MultiRoutes(props){
    const location = useLocation();
    const [loaded,setLoaded ] = React.useState(false);
    const [idx, setIdx] = React.useState()
    React.useEffect(()=>{
       window.addEventListener('load',async()=>{
         setIdx(props.listArticle.findIndex(item=> item.slug===location.pathname.substring(7)));
         if(props.article!==undefined&&props.listArticle[idx]!==undefined
          &&props.article.slug===props.listArticle[idx].slug&&JSON.stringify(props.article)!==JSON.stringify({})){
            console.log(loaded)
          setLoaded(true);
           document.title=props.article.title;
         }
         else{     
           props.changeStateProps('article',props.listArticle[idx]);
         }
       })
    })
    return(
            <Route exact path={location.pathname} render={()=>(
                <React.Fragment>{
                    loaded?(
                        <React.Fragment>
                          {JSON.stringify(props.listArticle[idx])}
                        </React.Fragment>                        
                    ):(
                        <CircularProgress size={40}/>
                    )
                }
                </React.Fragment>
            )
            } />
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        article: state.main.article,
        ...ownProps
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      changeStateProps: (prop, value) => {
        dispatch(changeStateProps(prop, value))
      }
    }
  }
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MultiRoutes);